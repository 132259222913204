import React from 'react';
import '../styles/landing.css';
import '../styles/navigation.css';

const Footer = () => {
    return (
        <div class='footer content-container'>
            <div class='section'>
                <div class='logo'>
                    <img src='./images/bw-logo.png' alt='footer logo' />
                </div>
                {/* <div class="robocup-logo">
                <img src="https://www.robocup.org/assets/frontend/robocup_logo_no_bg-824a0338f57b2804e73903286f5e9eda1dc1b51c1070a900f33b7ea1d9efa3e4.png"
                    alt="robocup logo" />
            </div> */}
                {/* <p>2022 RobocupCroatia</p> */}
            </div>
            <div class='section'>
                <div class='row'>
                    {/* <a href='/'>About Robocup Junior</a>
                    <a href='/'>About Robocup Junior</a>
                    <a href='/'>About Robocup Junior</a>
                    <a href='/'>About Robocup Junior</a>
                    <a href='/'>About Robocup Junior</a>
                    <a href='/'>About Robocup Junior</a> */}
                </div>
                <div class='row'>
                    <p class='text'>
                        Hrvatsko društvo za robotiku
                        <br />
                        Julija Knifera 4 10020 Zagreb
                        <br />
                        IBAN:HR3623600001503185054
                        <br />
                        OIB:68596604054
                    </p>
                </div>
            </div>
            <div class='footer-mascot'>
                <img src='/images/mascot-1.png' alt='footer mascot 1' />
                <img src='/images/mascot-2.png' alt='footer masctor 2' />
            </div>
            <p className='bottom-text'>
                <a href='/'>info@rcjcroatia2023.eu</a>
            </p>
        </div>
    );
};

export default Footer;
