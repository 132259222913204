import React from 'react';
import Rotator from '../components/rotator.jsx';
import '../styles/landing.css';
import '../styles/article.css';
import SEO from '../components/SEO.jsx';
import VideoSlider from '../components/videoSlider.jsx';

const Soccer = () => {
    return (
        <div>
            <SEO
                title='Soccer'
                description='Robocup junior Soccer is a category in which autonomous robots play against each other on a field that is like a real soccer field. Soccer is consisted...'
            />

            <Rotator image={['/images/soccer-1.jpg', '/images/soccer-2.jpg', '/images/soccer-3.jpg']} slider={true}/>

            <div class='article-page'>
                <div class='content content-container'>
                    <h1 class='title'>Soccer</h1>
                    <p>
                        Robocup junior Soccer is a category in which autonomous robots play against each other on a
                        field that is like a real soccer field. Soccer is consisted of two main leagues which are Soccer
                        Lightweight and Soccer Open where robots are playing 2-on-2. There is also an Entry league for
                        beginners where robots are playing autonomously with Fischertechnik or Lego kits 1-on-1.
                    </p>

                    <h2>Soccer Entry</h2>
                    <p>
                        Soccer Entry is intended for the beginners up to 19 years old. All the robots must be created
                        from Fischertechnik or Lego kits. Each team has 1 robot. They compete on a soccer field rounded
                        with walls so there is no “out of bounds”. The ball that will be used is IR ball.
                    </p>
                    <a
                        href='/files/RoboCupJunior-Soccer-Entry-Rules-2023.pdf
'
                        class='file'
                        target='_blank'>
                        <div class='type'>
                            <p>PDF</p>
                        </div>
                        <div class='name'>
                            <p>Soccer Entry rules</p>
                        </div>
                    </a>

                    <h2>Soccer Lightweight Entry</h2>
                    <p>
                        Soccer Entry Lightweight is intended for the beginners up to 19 years old. It is a challenge
                        where robots up to 1.1kg play soccer against each other 1 on 1. Each team has 1 robot. They
                        compete on a soccer field rounded with walls so there is no “out of bounds”. The ball that will
                        be used is IR ball.
                    </p>
                    <a
                        href='https://robocupjuniortc.github.io/soccer-rules-entry/2023-draft-rules/rules.pdf'
                        class='file'
                        target='_blank'>
                        <div class='type'>
                            <p>PDF</p>
                        </div>
                        <div class='name'>
                            <p>Soccer Lightweight Entry rules</p>
                        </div>
                    </a>

                    <h2>Soccer Lightweight</h2>
                    <p>
                        Soccer Lightweight is a challenge where robots up to 1.1kg play soccer against each other with
                        two robots per team. The game is played with a ball with infrared illumination so robots can
                        track it using IR sensors. All team members must be between 14 and 19 of age.
                    </p>
                    <a
                        href='https://robocupjuniortc.github.io/soccer-rules/master/rules.pdf'
                        class='file'
                        target='_blank'>
                        <div class='type'>
                            <p>PDF</p>
                        </div>
                        <div class='name'>
                            <p>Soccer Lightweight rules</p>
                        </div>
                    </a>

                    <h2>Soccer Open</h2>
                    <p>
                        Soccer Open is a more advanced category than it's Lightweight and Entry counterparts. Since the
                        ball does not emit any infra-red light, teams must rely on computer vision systems to detect it
                        which brings many challenges, but also opportunities as it can be used to detect other robots as
                        well. The rules of Soccer Open allow teams to have heavier robots with larger batteries than in
                        other categories which opens more opportunities for new and innovative ideas. All team members
                        must be between 14 and 19 of age.
                    </p>
                    <a
                        href='https://robocupjuniortc.github.io/soccer-rules/master/rules.pdf'
                        class='file'
                        target='_blank'>
                        <div class='type'>
                            <p>PDF</p>
                        </div>
                        <div class='name'>
                            <p>Soccer Open rules</p>
                        </div>
                    </a>

                    <h2>Soccer Small Size League {'(SSL-Jr)'}</h2>
                    <p>
                        This experimental league aims to promote robotic soccer with a focus on programming and match
                        strategy. The participants are completely free from any material realization, be it design,
                        manufacture or maintenance before and during the competition. The robots, the field and the
                        computer system are provided by the organizers and are identical for each match.
                    </p>
                    <a href='/files/SSL-Jr league rules.pdf' class='file' target='_blank'>
                        <div class='type'>
                            <p>PDF</p>
                        </div>
                        <div class='name'>
                            <p>Soccer Small Size League rules</p>
                        </div>
                    </a>
                    <VideoSlider
                        videos={[
                            '/videos/Robo Cup 2023 - Soccer Entry.mp4',
                            '/videos/Robo Cup 2023 - Soccer Lightwew 1.mp4',
                            '/videos/Robo Cup 2023 - Soccer Lightwew 2.mp4',
                            '/videos/Robo Cup 2023 - Soccer Open.mp4',
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

export default Soccer;
