import React from 'react';
import '../styles/navigation.css';
import ReactPlayer from 'react-player';
import Slider from "react-slick";

const Rotator = ({ image = '/images/Varazdin_17.jpg', video = '', controls = true, slider = false }) => {
    return (
        <>
            <div class='rotator'>
                <div class='swiper-wrapper'>
                    {slider ? (
                        <div className='slider'>
                            <Slider dots={true} 
                            infinite={true}
                            speed={500}
                            slidesToShow={1}
                            slidesToScroll={1}
                            autoplay={true}
                            autoplaySpeed={5000}
                            nextArrow={
                                <div class='slick-next slick-prev'>
                                    <img src='/images/Strelica-bijela.svg' alt='arrow' />
                                </div>
                            }
                            prevArrow={
                                <div class='slick-next'>
                                    <img src='/images/Strelica-bijela.svg' alt='arrow' />
                                </div>
                            }>
                                {image.map(img => (
                                    <div class='slider-image swiper-slide'>
                                        <img src={img} alt='rotator image' />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    ): (
                        <>
                            {video.length !== 0 ? (
                                <div class='slider-image swiper-slide slider-video'>
                                    <ReactPlayer url={video} controls={true} playing={true} muted loop={true} />
                                </div>
                            ) : (
                                <div class='slider-image swiper-slide'>
                                    <img src={image} alt='rotator image' />
                                </div>
                            )}
                        </>
                    )}

                    {/* <div class='slider-image swiper-slide'>
                        <img src='./images/Varazdin_17.jpg' alt='rotator image' />
                    </div>
                    <div class='slider-image swiper-slide'>
                        <img src='./images/Varazdin_17.jpg' alt='rotator image' />
                    </div>
                    <div class='slider-image swiper-slide'>
                        <img src='./images/Varazdin_17.jpg' alt='rotator image' />
                    </div>
                    <div class='slider-image swiper-slide'>
                        <img src='./images/Varazdin_17.jpg' alt='rotator image' />
                    </div> */}
                </div>
                {/* <div class="swiper-button-next">
                    <img src="./images/Strelica-bijela.svg" alt="arrow" />
                </div>
                <div class="swiper-button-prev">
                    <img src="./images/Strelica-bijela.svg" alt="arrow" />
                </div>
                <div class="swiper-pagination"></div> */}
            </div>
        </>
    );
};

export default Rotator;
