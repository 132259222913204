import React from 'react';
import Rotator from '../components/rotator.jsx';
import '../styles/landing.css';
import '../styles/article.css';
import SEO from '../components/SEO.jsx';

const TravelInformation = () => {
    return (
        <div>
            <SEO title='Travel information' description='Under construction!' />

            <Rotator />

            <div class='article-page'>
                <div class='content content-container'>
                    <h1 class='title'>Travel information</h1>
                    <h2>Location of Arena Varaždin</h2>
                    <p>
                        <a target='_blank' href='https://maps.app.goo.gl/CR9kWMuzvxfEr5v29?g_st=ic'>
                            Arena Varaždin
                        </a>
                    </p>
                    <h2>Getting to Varaždin: Transportation Options</h2>
                    <p>
                        Varaždin is a charming city located in northern Croatia. If you are planning to attend the
                        upcoming Varaždin Robotics Challenge, you might be wondering about the best ways to get there.
                        Luckily, there are several transportation options available to you.
                    </p>
                    <h2>By Air:</h2>
                    <p>
                        If you are coming from abroad, the easiest way to get to Varaždin is by flying into Zagreb
                        Airport. Zagreb Airport is located about 100 km away from Varaždin. From the airport, you can
                        take a taxi, rent a car or use public transportation to reach Varaždin. The Zagreb Airport
                        website provides information on flights, parking, ground transportation, and more. Zagreb
                        Airport:{' '}
                        <a target='_blank' href='https://www.zagreb-airport.hr/en'>
                            https://www.zagreb-airport.hr/en
                        </a>
                    </p>
                    <h2>By Train:</h2>
                    <p>
                        Another option is to take a train to Varaždin. The Zagreb Central Railway Station is located in
                        the heart of the city, and from there, you can take a direct train to Varaždin. The journey
                        takes about 2 hours. You can check train schedules and purchase tickets on the Croatian Railways
                        website. Croatian Railways: <a href='https://www.hzpp.hr/en'>https://www.hzpp.hr/en</a>
                    </p>
                    <h2>By Bus:</h2>
                    <p>
                        Taking a bus is also a convenient way to reach Varaždin. The Zagreb Central Bus Station is
                        located next to the Zagreb Central Railway Station. From there, you can take a direct bus to
                        Varaždin. The journey takes about 1.5 hours. You can find bus schedules and purchase tickets on
                        the Zagreb Bus Terminal website. Zagreb Bus Terminal:{' '}
                        <a target='_blank' href='https://www.akz.hr/en'>
                            https://www.akz.hr/en
                        </a>
                    </p>
                    <p>
                        Once you arrive in Varaždin, the event venue is easily accessible by public transportation or
                        taxi. We recommend checking out the Varaždin Public Transport website for more information on
                        buses and taxis in the area.
                    </p>
                    <p>
                        We hope this information helps you plan your trip to Varaždin for the Robotics Challenge. Safe
                        travels!
                    </p>
                    <a
                        href='/files/Linija 6 ROBOCUP.pdf'
                        class='file'
                        target='_blank'>
                        <div class='type'>
                            <p>PDF</p>
                        </div>
                        <div class='name'>
                            <p>Bus schedule</p>
                        </div>
                    </a>
                    <h2>Route: Airport Zagreb - Varaždin</h2>
                    <img src='/images/zagreb-vz-karta.png' alt='map' />
                    <h2>Varaždin places</h2>
                    <img src='/images/varazdin-karta.png' alt='map' />
                </div>
            </div>
        </div>
    );
};

export default TravelInformation;
