import React from 'react';
import Rotator from '../components/rotator.jsx';
import '../styles/landing.css';
import '../styles/article.css';
import SEO from '../components/SEO.jsx';
import VideoSlider from '../components/videoSlider.jsx';

const OnStage = () => {
    return (
        <div>
            <SEO
                title='OnStage'
                description='RoboCupJunior OnStage invites teams to design, develop, build and program creative and autonomous physical robots. Teams are encouraged to uses a wide...'
            />

            <Rotator image={['/images/onstage-1.jpg', '/images/onstage-2.jpg']} slider={true} />

            <div class='article-page'>
                <div class='content content-container'>
                    <h1 class='title'>OnStage</h1>
                    <p>
                        RoboCupJunior OnStage invites teams to design, develop, build and program creative and
                        autonomous physical robots. Teams are encouraged to uses a wide variety of technology to engage
                        an audience.
                    </p>
                    <p>
                        Three subcategories are present inside the OnStage category:
                        <br />- <strong>OnStage First Steps</strong> - 5 to 9 years old{' '}
                        {'(age as of 1st of July 2023.)'}
                        <br />- <strong>OnStage Preliminary</strong> -{' '}
                        {'9 to 19 years old (age as of 1st of July 2023.)'}
                        <br />- <strong>OnStage Advanced</strong> - {'13 to 19 years old(age as of 1st of July 2023.)'}
                        <br />
                    </p>
                    <a
                        href='/files/RCJ 4. European RoboCup Junior competition - ENG .pdf'
                        class='file'
                        target='_blank'>
                        <div class='type'>
                            <p>PDF</p>
                        </div>
                        <div class='name'>
                            <p>OnStage rules</p>
                        </div>
                    </a>
                    <p>
                        The objective is to create performance of 1 to 2 minutes using robots. This includes a range of
                        possible performances, such as dance, storytelling, theatre or art installations. The
                        performance may involve up to two team members on the stage at any one time but this is
                        optional. The performance may involve music, but this is optional too.
                    </p>
                    <p>
                        The emphasis must be on the robotic performance rather than the human performers, but the
                        interaction and harmony between robots and humans on stage and in performance is welcome.
                    </p>
                    <p>
                        Teams are encouraged to be creative, innovative, and entertaining, in both the design of the
                        robots and in the design of the overall performance.
                    </p>
                    <p>
                        All teams are judged in the following areas: a Technical Description Paper (TPD), Technical
                        Demonstration, Technical Interview, and an OnStage Performance.
                    </p>

                    <p>
                        Participation in a particular category depends on the age of the team members and on their
                        experience and complexity of making robots and performances.
                    </p>
                    {/*  <a href="/files/RCJ 4. European RoboCup Junior competition - ENG .pdf" class="file" target="_blank">
                <div class="type">
                    <p>PDF</p>
                </div>
                <div class="name">
                    <p>OnStage rules (ENG)</p>
                </div>
            </a>
            <a href="/files/RCJ 4. European RoboCup Junior competition - HRV .pdf" class="file" target="_blank">
                <div class="type">
                    <p>PDF</p>
                </div>
                <div class="name">
                    <p>OnStage rules (HR)</p>
                </div>
            </a> */}
                    <VideoSlider
                        videos={[
                            '/videos/Robo Cup 2023 - OnStage Advanced.mp4',
                            '/videos/Robo Cup 2023 - OnStage Priminary.mp4',
                            '/videos/Robo Cup 2023 - OnStage Kids.mp4',
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

export default OnStage;
