import React from 'react';
import Rotator from '../components/rotator.jsx';
import '../styles/landing.css';
import '../styles/article.css';
import SEO from '../components/SEO.jsx';

const SocialActivities = () => {
    return (
        <div>
            <SEO title='Social Activities' description='Chess tournament...' />

            <Rotator />

            <div class='article-page'>
                <div class='content content-container'>
                    <h1 class='title'>Social activities</h1>

                    <h2>Robo Party</h2>
                    <img src="/images/robo-party.png" alt="robo party image" />

                    <h2>Chess tournament</h2>
                    <p></p>
                    <img src="/images/Plakat za aktivnost šah.jpg" alt="Chess tournament" />
                </div>
            </div>
        </div>
    );
};

export default SocialActivities;
