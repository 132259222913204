import React from 'react';
import '../styles/navigation.css';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import '../styles/videoSlider.css';
import '../styles/landing.css';

const VideoSlider = ({ videos = [] }) => {
    return (
        <>
            <div className='video-slider' id='video-player'>
                <Slider
                    dots={true}
                    infinite={true}
                    slidesToShow={1}
                    slidesToScroll={1}
                    arrows={true}
                    nextArrow={
                        <div class='slick-next'>
                            <img src='/images/Strelica-bijela.svg' alt='arrow' />
                        </div>
                    }
                    prevArrow={
                        <div class='slick-next'>
                            <img src='/images/Strelica-bijela.svg' alt='arrow' />
                        </div>
                    }>
                    {videos.map((video, index) => (
                        <div className='video' key={index}>
                            <ReactPlayer url={video} muted={true} playing={false} controls={true} />
                        </div>
                    ))}
                </Slider>
            </div>
        </>
    );
};

export default VideoSlider;
