import React from 'react';
import '../../styles/landing.css';

const LandingSponsors = ({ title, sponsors }) => {
    return (
        <>
            <div class='divider'></div>

            <div class='sponsors content-container'>
                <p class='title'>{title}</p>
                {/* <p class="text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
            the
            industry's standard dummy text ever since theLorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the
        </p> */}
                <div class='image-grid'>
                    {/* <img src='/images/mu-logo-en.png' alt='logo' /> */}
                    {sponsors.map((sponsor) => sponsor)}
                </div>
            </div>
        </>
    );
};

export default LandingSponsors;
