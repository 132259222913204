import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title = '', description = '' }) => {
    return (
        <Helmet>
            <title>{`${description.length === 0 ? '' : `${title} - `}European Robocup Junior 2023`}</title>
            <meta name='description' content={description} />
        </Helmet>
    );
};

export default SEO;
