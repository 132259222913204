import React from 'react';
import Rotator from '../components/rotator.jsx';
import '../styles/landing.css';
import '../styles/article.css';
import SEO from '../components/SEO.jsx';

const Accomodation = () => {
    return (
        <div>
            <SEO title='Accomodation' description='Under construction!' />

            <Rotator />

            <div class='article-page'>
                <div class='content content-container'>
                    <h1 class='title'>Accomodation</h1>
                    <p>
                        In cooperation with the organizer of the European RoboCup Junior Varaždin 2023., we have
                        prepared for you an offer for accommodation in and around Varaždin, as well as an offer for
                        transfers and excursions. We are happy to be at your disposal. Welcome to Varaždin!
                    </p>
                    <a href='/files/RoboCup Junior Varaždin 2023..pdf' class='file' target='_blank'>
                        <div class='type'>
                            <p>PDF</p>
                        </div>
                        <div class='name'>
                            <p>Accommodation in Varaždin</p>
                        </div>
                    </a>
                    <a href='/files/LOCAL EXCURSIONS & SIGHTSEEING.pdf' class='file' target='_blank'>
                        <div class='type'>
                            <p>PDF</p>
                        </div>
                        <div class='name'>
                            <p>Local excursions & sightseeing</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Accomodation;
