import React from 'react';
import Rotator from '../components/rotator.jsx';
import '../styles/landing.css';
import '../styles/article.css';
import SEO from '../components/SEO.jsx';

const Programme = () => {
    return (
        <div>
            <SEO title='Programme' description='Under construction!' />

            <Rotator />

            <div class='article-page'>
                <div class='content content-container'>
                    <h1 class='title'>Programme</h1>

                    <h2>Tuesday - 06. June</h2>
                    <p>- Registration 17-20</p>

                    <h2>Wednesday - 07. June</h2>
                    <p>
                        - Registration 8-12
                        <br />
                        - Opening ceremony 11-12
                        <br />- Competition 12-18
                    </p>

                    <h2>Thursday - 08. June</h2>
                    <p>- Competition 10-18</p>

                    <h2>Friday - 09. June</h2>
                    <p>
                        - Competition 10-18
                        <br />- Social activities 16-22
                    </p>

                    <h2>Saturday - 10. June</h2>
                    <p>
                        - Finals 9-13
                        <br />- Closing ceremony 14-15
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Programme;
