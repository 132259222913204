import React from 'react';
import Rotator from '../components/rotator.jsx';
import '../styles/landing.css';
import '../styles/article.css';
import SEO from '../components/SEO.jsx';

const MathWorks = () => {
    return (
        <div>
            <SEO title='MathWorks seminar' description='MathWorks supports the RoboCup Junior competition because it aligns with our mission to inspire and support education in engineering and science...' />

            <Rotator />

            <div class='article-page'>
                <div class='content content-container'>
                    <img src="/images/09_MW_logo_RGB.jpg" alt="Speaker" className='small-image'/>
                    <h1 class='title'>MathWorks seminar</h1>

                    <p>
                        MathWorks supports the RoboCup Junior competition because it aligns with our mission to inspire and support education in engineering and science. RoboCup Junior is a program designed to introduce young students to the world of robotics and inspire them to pursue careers in STEM fields. The competition challenges teams of students to design, build, and program autonomous robots to complete various tasks and challenges.
                        As a leading provider of software tools for technical computing and simulation, the RoboCup Junior is an opportunity to engage with the next generation of engineers and scientists who will shape the future of robotics and automation. By supporting the RoboCup Junior competition, MathWorks can help to inspire and motivate students to pursue careers in these fields, and provide them with the tools and resources they need to succeed.
                    </p>

                    <p>
                        MathWorks is the leading developer of mathematical computing software. Engineers and scientists worldwide rely on its products to accelerate the pace of discovery, innovation, and development.
                        MATLAB®, the language of engineers and scientists, is a programming environment for algorithm development, data analysis, visualization, and numeric computation.
                        Simulink® is a block diagram environment for simulation and Model-Based Design of multidomain and embedded engineering systems. The company produces over 120 additional products for specialized tasks such as image and signal processing, control systems, robotics, and deep learning.
                    </p>

                    <img src="/images/IMG_avc.jpg" alt="Speaker" className='small-image'/>

                    <p>
                        The seminar will be conducted by Ascension Vizinho-Coutry, Principal Technical Marketing Pre-University and CPGE at The MathWorks. She has been in the MathWorks team for more than 18 years, of which more than 8 years she has been working in the field of youth education in the STEM.
                    </p>

                    <p>
                        To participate in the seminar, participants should have their own computer, and the online version of MathWorks software will be used. Up to 20 participants are planned in one session, and the plan is to show how robots for RCJ can be programmed with the help of MathWorks & Simulink programs. In case of increased interest, it is possible to add additional terms for the seminar.
                    </p>

                    <p>To participate in the seminar, you can register using the registration form at this <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhzan3g97DmAaiiOhVyk66q4RJjqOZ0hbw6PbCnG_O2mBA8Q/viewform?vc=0&c=0&w=1&flr=0" target='_blank'>link</a>.</p>
                </div>
            </div>
        </div>
    );
};

export default MathWorks;
