import React from 'react';
import Rotator from '../components/rotator.jsx';
import '../styles/landing.css';
import '../styles/article.css';
import SEO from '../components/SEO.jsx';

const Organization = () => {
    return (
        <div>
            <SEO
                title='Organization'
                description='Croatian Robotic Society (CRS) was founded in 1994 as first and leading Croatian national robotic association. It gathered pioneers of Croatian robotics...'
            />

            <Rotator image='/images/organization-cover.png' />

            <div class='article-page'>
                <div class='content content-container'>
                    <h1 class='title'>Croatian Robotic Society</h1>
                    <p>
                        Croatian Robotic Society (CRS) was founded in 1994 as first and leading Croatian national
                        robotic association. It gathered pioneers of Croatian robotics and preceded Croatian Robotic
                        Association. Its foundation was supported by Department of Technical Culture of Matica hrvatska
                        and Croatian Association of Technical Culture (CATC). CRS has been member of CATC since its
                        foundation. CRS is also one of the five that founded Croatian Robotic Association, on 15th
                        December 2012, leading organization of Croatian robotics.
                    </p>
                    <p>
                        Since its foundation CRS has had members from all over the Croatia. Until today, several hundred
                        people from different social environments have been members of the organization: industry,
                        university, primary and secondary education and also national institutions. Because of the above
                        reasons CRS became respected organization with which many robotic professionals and enthusiasts
                        cooperate. Organizations influence on robotic proffesions and society exceeds expected
                        influence, based on the number of organizations members.
                    </p>
                    <p>
                        Most significant social contribution from CRS and its members during the last few years has been
                        in robotics educations at all the levels of education, but especially in education of children
                        in primary and secondary schools. Members of CRS created one of the most regionaly advanced
                        system of extracurricular education in robotics, intended for primary school students.
                    </p>
                    <p>
                        In 1998 CRS started robotic educations for secondary school students. That educational system,
                        based on the state-of-art systems in the USA, was one of the first of its kind in the region.
                        The methodology of work makes the system unique in many segments. Over time, robotic educations
                        spread all over the Croatia. Several hundred secondary school students and many teachers
                        participated in the educations, either by being educated or educating others. CRS continuously
                        invests efforts in establishing regular (curricular) robotics education in primary and secondary
                        schools.
                    </p>
                    <p>
                        The next significant step was inclusion in RoboCup Junior competition in 2011, since when CRS
                        has been the main promoter and organizer of that competition for young robotics enthusiasts
                        under the age of 19. Since their debut at RoboCup Junior in 2011, Croatian teams have regularly
                        won medals or have been at the very top of the competition.That gained CRS and Croatia a loto of
                        prestige in international robotic community. You can find more about the competition itself at
                        the webpage (<a href='https://www.robocupcroatia.com'>https://www.robocupcroatia.com</a>
                        ), as well as in the RoboCup competition section.
                    </p>
                    <p>
                        In 2012 Croatia became member of the RoboCup Junior organization, univ.dip.ing. Ivica Kolarić
                        was chosen as national representative.
                    </p>
                    <p>
                        During the early years of CRS, one of the significant activities was taking part in establishing
                        Center for practical robotics in 2005. In the meantime authority over the Center was transferred
                        to Croatian Robotic Association, which means that CRS does participates no more in itswork.
                    </p>
                    <p>
                        Since 2015, CRS has been organizing summer robotic camps in Vrboska, on the island Hvar, during
                        the last week of the August. It has also been organizing winter robotic camps in Stubičke
                        Toplice during winter holidays. Most important CRS partner in this activites is Robofreak
                        organization from the city Varaždin.
                    </p>
                    <p>
                        CRS also instigates and supports many activities related to cooperation with scientific and
                        professional organizations, both in the country and abroad. For example, organizing
                        international robotic events and membership in the RoboCup.
                    </p>
                    <p>
                        During more than 25 years of its existence, CRS has become recognizable place not only for the
                        promotion and popularization of robotics and robotics education, but also for meetings of all
                        robotics enthusiasts.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Organization;
