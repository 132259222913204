import React from 'react';
import Rotator from '../components/rotator.jsx';
import '../styles/landing.css';
import '../styles/article.css';
import SEO from '../components/SEO.jsx';
import VideoSlider from '../components/videoSlider.jsx';

const Rescue = () => {
    return (
        <div>
            <SEO
                title='Rescue'
                description='An autonomous robot should follow the black line, overcoming gaps, obstacles, ramps, speed bumps, intersections and other challenging elements that...'
            />

            <Rotator image={['/images/line-1.jpg', '/images/line-2.jpg', '/images/line-3.jpeg', '/images/line-5.jpg', '/images/maze-1.jpeg', '/images/maze-2.jpg', '/images/maze-3.jpg', '/images/maze-4.jpg', '/images/cospace-1.jpg', '/images/cospace-2.jpg', '/images/cospace-3.jpg']} slider={true} />

            <div class='article-page'>
                <div class='content content-container'>
                    <h1 class='title'>Rescue</h1>

                    <h2>Line</h2>
                    <p>
                        An autonomous robot should follow the black line, overcoming gaps, obstacles, ramps, speed
                        bumps, intersections and other challenging elements that might get into its way, as it tries to
                        reach the evacuation zone to rescue victims.
                    </p>
                    <p>Two subcategories are present inside the Recue Line category:</p>
                    <p>
                        <strong>Rescue Line Entry</strong> - 14 and under years old (age as of 1st of July 2023.)
                    </p>
                    <a href='/files/Rescue-Line-Entry-Rules-2022.pdf' class='file' target='_blank'>
                        <div class='type'>
                            <p>PDF</p>
                        </div>
                        <div class='name'>
                            <p>Rescue Line Entry rules</p>
                        </div>
                    </a>

                    <p>
                        <strong>Rescue Line</strong> - 19 and under years old (age as of 1st of July 2023.)
                    </p>
                    <a href='/files/2023-RoboCupJunior-Rescue-Line-v1.0-Draft.pdf' class='file' target='_blank'>
                        <div class='type'>
                            <p>PDF</p>
                        </div>
                        <div class='name'>
                            <p>Rescue Line rules</p>
                        </div>
                    </a>

                    <h2>Maze</h2>
                    <p>
                        An autonomous robot is placed into a maze. It is tasked with exploring it and identifying
                        victims on its way, dropping them a rescue kit. However, the terrain is very dangerous, so the
                        robot should be able to overcome speed bumps, ramps, different coloured tiles and all the other
                        challenges that await.
                    </p>
                    <p>Two subcategories are present inside the Recue Maze category:</p>
                    <p>
                        <strong>Rescue Maze Entry</strong> - 19 and under years old (age as of 1st of July 2023.)
                    </p>
                    <a href='/files/Rescue-Maze-Entry-Rules-2022.pdf' class='file' target='_blank'>
                        <div class='type'>
                            <p>PDF</p>
                        </div>
                        <div class='name'>
                            <p>Rescue Maze Entry rules</p>
                        </div>
                    </a>
                    <p>
                        <strong>Rescue Maze</strong> - 19 and under years old (age as of 1st of July 2023.)
                    </p>
                    <a href='/files/2023-RoboCupJunior-Rescue-Maze-v1.0-Draft.pdf' class='file' target='_blank'>
                        <div class='type'>
                            <p>PDF</p>
                        </div>
                        <div class='name'>
                            <p>Rescue Maze rules</p>
                        </div>
                    </a>

                    <h2>CoSpace</h2>
                    <p>
                        Two virtual robots are placed into a simulator, consisting of two separate worlds. In each world
                        robots should collect objects of different colors, avoid traps and obstacles and safely deposit
                        collected objects into one of the designated zones to gain points. Team whose robot collects
                        more points wins the match.
                    </p>
                    <p>Two subcategories are present inside the CoSpace category:</p>
                    <p>
                        <strong>CoSpace Preliminary</strong> - 14 and under years old (age as of 1st of July 2023.)
                    </p>
                    <p>
                        <strong>CoSpace Advanced</strong> - 19 and under years old (age as of 1st of July 2023.)
                    </p>
                    <a href='/files/2021_RescueSimulation_Rules_final01.pdf' class='file' target='_blank'>
                        <div class='type'>
                            <p>PDF</p>
                        </div>
                        <div class='name'>
                            <p>CoSpace rules</p>
                        </div>
                    </a>

                    <h2>Simulation</h2>
                    <p>
                        Rescue Simulation runs on a newly developed platform Webots-Erebus. The virtual robot is tasked
                        with maze exploration and mapping while identifying victims on its way. It takes inspiration
                        from RCJ Rescue Maze, while adding new areas with difficult terrain as well as incorporating
                        some elements of RoboCup Rescue Major.
                    </p>
                    <a href='/files/2023-RoboCupJunior-Rescue-Simulation-v1.0-Draft.pdf' class='file' target='_blank'>
                        <div class='type'>
                            <p>PDF</p>
                        </div>
                        <div class='name'>
                            <p>Rescue Simulation rules</p>
                        </div>
                    </a>
                    <VideoSlider
                        videos={[
                            '/videos/Robo Cup 2023 - Rescue Line.mp4',
                            '/videos/Robo Cup 2023 - Rescue Line Entry.mp4',
                            '/videos/Robo Cup 2023 - Maze.mp4',
                            '/videos/Robo Cup 2023 - Maze Entry.mp4',
                            '/videos/Robo Cup 2023 - CO Space.mp4',
                            '/videos/Robo Cup 2023 - CO Space Entry.mp4',
                            '/videos/Robo Cup 2023 - Rascue Simulation.mp4',
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

export default Rescue;
