import React from 'react';
import Rotator from '../components/rotator.jsx';
import '../styles/landing.css';
import '../styles/article.css';
import SEO from '../components/SEO.jsx';

const Registration = () => {
    return (
        <div>
            <SEO title='Registration' description='Under construction!' />

            <Rotator />

            <div class='article-page'>
                <div class='content content-container'>
                    <h1 class='title'>Qualification</h1>
                    <p>
                        Qualifications for the European Championship will be managed by the national committees. Please
                        contact the RoboCupJunior national representative of your country for information.
                    </p>
                    <h1 class='title'>Registration</h1>
                    <p>
                        February 5: Slot request from Regionals Representative
                        <br />
                        March 20: Start of registration teams for competition
                        <br />
                        May 9: End of registration
                        <br />
                        Team can't register/pay after May 9th, 2023
                    </p>

                    <h2>REGISTRATION FEES for the COMPETITION</h2>
                    <p>
                        - 100 EUR per team
                        <br />
                        - 50 EUR per mentor
                        <br />
                        - 50 EUR per team member
                        <br />- 50 EUR per additional mentor or companion
                    </p>

                    <h2>REGISTRATION FEE should be transferred to the bank account of:</h2>
                    <p>
                        Hrvatsko društvo za robotiku
                        <br />
                        Address: Julija Knifera 4, 10020 Zagreb
                        <br />
                        OIB 68596604054
                        <br />
                        IBAN: HR3623600001503185054
                        <br />
                        Zagrebačka banka
                        <br />
                        SWIFT: ZABAHR2X
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Registration;
