import React from 'react';
import Rotator from '../components/rotator.jsx';
import '../styles/landing.css';
import '../styles/article.css';
import SEO from '../components/SEO.jsx';

const Contact = () => {
    return (
        <div>
            <SEO title='Contact' description='' />
            <Rotator />

            <div class='article-page contact-page'>
                <div class='content content-container'>
                    <h1 class='title'>Contact information</h1>
                    <p>
                        Hrvatsko društvo za robotiku
                        <br />
                        Julija Knifera 4 10020 Zagreb
                        <br />
                        IBAN:HR3623600001503185054
                        <br />
                        OIB:68596604054
                    </p>
                    <br />
                    <p>
                        General informations - <a href='mailto:info@rcjcroatia2023.eu'>info@rcjcroatia2023.eu</a>
                    </p>
                    <p>
                        Accounting and financial information -{' '}
                        <a href='mailto:administration@rcjcroatia2023.eu'>administration@rcjcroatia2023.eu</a>
                    </p>
                    <p>
                        Rescue League - <a href='mailto:rescue@rcjcroatia2023.eu'>rescue@rcjcroatia2023.eu</a>
                    </p>
                    <p>
                        Soccer League - <a href='mailto:soccer@rcjcroatia2023.eu'>soccer@rcjcroatia2023.eu</a>
                    </p>
                    <p>
                        On Stage League - <a href='mailto:onstage@rcjcroatia2023.eu'>onstage@rcjcroatia2023.eu</a>
                    </p>
                    <p>
                        Press and media - <a href='mailto:marketing@rcjcroatia2023.eu'>marketing@rcjcroatia2023.eu</a>
                    </p>
                    <p>
                        Business communication -{' '}
                        <a href='mailto:management@rcjcroatia2023.eu'>management@rcjcroatia2023.eu</a>
                    </p>

                    <h2>European RoboCup Junior Representatives:</h2>
                    <table>
                        <tr>
                            <td>Austria</td>
                            <td>Sabrina Rubenzer</td>
                            <td>
                                <a href='mailto:sabrina.rubenzer@technikum-wien.at'>
                                    sabrina.rubenzer@technikum-wien.at
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td>Belgium</td>
                            <td>Joachim Mathieu</td>
                            <td>
                                <a href='mailto:joachim.mathieu@vub.ac.be'>joachim.mathieu@vub.ac.be</a>
                            </td>
                        </tr>

                        <tr>
                            <td>Croatia</td>
                            <td>Ivica Kolaric</td>
                            <td>
                                <a href='mailto:uito@net.hr'>uito@net.hr</a>
                            </td>
                        </tr>

                        <tr>
                            <td>Finland</td>
                            <td>Markku Tukiainen</td>
                            <td>
                                <a href='mailto:markku.tukiainen@uef.fi'>markku.tukiainen@uef.fi</a>
                            </td>
                        </tr>

                        <tr>
                            <td>France</td>
                            <td>Stéphane Brunel</td>
                            <td>
                                <a href='mailto:stephane.brunel@u-bordeaux.fr'>stephane.brunel@u-bordeaux.fr</a>
                            </td>
                        </tr>

                        <tr>
                            <td>Germany</td>
                            <td>Joachim Selke</td>
                            <td>
                                <a href='mailto:jselke@web.de'>jselke@web.de</a>
                            </td>
                        </tr>

                        <tr>
                            <td>Hungary</td>
                            <td>Agnes Simon</td>
                            <td>
                                <a href='mailto:bne.simon@gmail.com'>bne.simon@gmail.com</a>
                            </td>
                        </tr>

                        <tr>
                            <td>Italy</td>
                            <td>Tommaso Scarano</td>
                            <td>
                                <a href='mailto:nationalrepresentative@robocupjunior.it'>
                                    nationalrepresentative@robocupjunior.it
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td>Netherlands</td>
                            <td>Martin Klomp</td>
                            <td>
                                <a href='mailto:email@martinklomp.nl'>email@martinklomp.nl</a>
                            </td>
                        </tr>

                        <tr>
                            <td>Norway</td>
                            <td>Pavel Petrovic</td>
                            <td>
                                <a href='mailto:petrovic@idi.ntnu.no'>petrovic@idi.ntnu.no</a>
                            </td>
                        </tr>

                        <tr>
                            <td>Portugal</td>
                            <td>Rui Baptista</td>
                            <td>
                                <a href='mailto:ruivcbaptista@hotmail.com'>ruivcbaptista@hotmail.com</a>
                            </td>
                        </tr>

                        <tr>
                            <td>Russia</td>
                            <td>Evgeny Shandarov</td>
                            <td>
                                <a href='mailto:evgenyshandarov@gmail.com'>evgenyshandarov@gmail.com</a>
                            </td>
                        </tr>

                        <tr>
                            <td>Slovak Republic</td>
                            <td>Miro Kohut</td>
                            <td>
                                <a href='mailto:kohut@skse.sk'>kohut@skse.sk</a>
                            </td>
                        </tr>

                        <tr>
                            <td>Slovenia</td>
                            <td>Suzana Uran</td>
                            <td>
                                <a href='mailto:suzana.uran@gmail.com'>suzana.uran@gmail.com</a>
                            </td>
                        </tr>

                        <tr>
                            <td>Spain</td>
                            <td>Eduardo Gallego</td>
                            <td>
                                <a href='mailto:info@robocupjuniorspain.es'>info@robocupjuniorspain.es</a>
                            </td>
                        </tr>

                        <tr>
                            <td>Sweden</td>
                            <td>Fredrik Löfgren</td>
                            <td>
                                <a href='mailto:fredrik@eaproduktion.se'>fredrik@eaproduktion.se</a>
                            </td>
                        </tr>

                        <tr>
                            <td>Switzerland</td>
                            <td>Benedikt Koeppel</td>
                            <td>
                                <a href='mailto:rcj@benediktkoeppel.ch'>rcj@benediktkoeppel.ch</a>
                            </td>
                        </tr>

                        <tr>
                            <td>Turkey</td>
                            <td>Evren Olcay</td>
                            <td>
                                <a href='mailto:evrenolcay@gmail.com'>evrenolcay@gmail.com</a>
                            </td>
                        </tr>

                        <tr>
                            <td>UK</td>
                            <td>Pat Hughes</td>
                            <td>
                                <a href='mailto:pat.hughes8020@gmail.com'>pat.hughes8020@gmail.com</a>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Contact;
