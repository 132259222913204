import React from 'react';
import Rotator from '../components/rotator.jsx';
import '../styles/landing.css';
import '../styles/navigation.css';
import LandingSponsors from '../components/landing/landingSponsors.jsx';
import FooterLinkedArticles from '../components/landing/footerLinkedArticles.jsx';
import SEO from '../components/SEO.jsx';

const IndexPage = () => {
    return (
        <div>
            <SEO />
            <Rotator />
            <LandingSponsors
                title='Partners'
                sponsors={[
                    <img src='/images/VZ-zupanija.png' alt='logo' />,
                    <img src='/images/ETC.png' alt='logo' />,
                    <img src='/images/muzza_logo_hrobos.webp' alt='logo' />,
                    <img src='/images/foi-logo.png' alt='foi logo' />,
                    <img src='/images/partnermzo.png' alt='foi logo' />,
                ]}
            />
            <LandingSponsors
                title='Sponsors and Donors'
                sponsors={[
                    <img src='/images/01_Infobip_logo_vertical_rgb_color.png' alt='logo' />,
                    <img src='/images/Logo-Data.jpg' alt='logo' />,
                    <img src='/images/logo-varkom.jpg' alt='logo' />,
                    <img src='/images/VIS-promotex.jpg' alt='logo' />,
                    <img src='/images/konto-logo-zaglavlje.png' alt='logo' />,
                    <img src='/images/logo-fischer.jpg' alt='logo' />,
                    <img src='/images/LOGO_didacta.png' alt='logo' />,
                    <img src='/images/09_MW_logo_RGB.jpg' alt='logo' />,
                    <img src='/images/logo-emil-frey-digital.webp' alt='logo' />,
                    <img src='/images/logo atinel.png' alt='logo' />,
                    <img src='/images/logo bomark ambalaža.jpg' alt='logo' />,
                    <img src='/images/logo eccos inženjering.jpg' alt='logo' />,
                    <img src='/images/logo BS AUTO.jpg' alt='logo' />,
                    <img src='/images/3 Gebruder.png' alt='logo' />,
                    <img src='/images/3 Prikratki.png' alt='logo' />,
                    <img src='/images/3 Sick Mobilisis-01.webp' alt='logo' />,
                    <img src='/images/3 Školska knjiga.png' alt='logo' />,
                    <img src='/images/Vindija_Logo.png' alt='logo' />,
                    <img src='/images/KONCAR_logo_HRV_slogan.png' alt='logo' />,
                    <img src='/images/Arena-VZ_logo.png' alt='logo' />,
                    <img src='/images/Logo CRATIS kvadrat 2020.png' alt='logo' />,
                    <img src='/images/soing logo.jpg' alt='logo' />,
                    <img src='/images/Logo Habulan.png' alt='logo' />,
                    <img src='/images/Cotra-logo-01.png' alt='logo' />,
                    <img src='/images/Mikiz d.o.o..PNG' alt='logo' />,
                    <img src='/images/zoki pal logo.png' alt='logo' />,
                ]}
            />
            <FooterLinkedArticles />
        </div>
    );
};

export default IndexPage;
