import React from 'react';
import Rotator from '../components/rotator.jsx';
import '../styles/landing.css';
import '../styles/article.css';
import SEO from '../components/SEO.jsx';
// import Video from '/videos/European RoboCupJunior 2023, Varaždin - Documentary.mp4';

const Location = () => {
    return (
        <div>
            <SEO
                title='Location'
                description='Unique in so many ways, Croatia’s roots stem from ancient times and its great cultural wealth tells of a turbulent history going back to the Roman era and...'
            />
            {/* <Rotator video={'/videos/European RoboCupJunior 2023, Varaždin - Documentary.mp4'} /> */}
            <Rotator video='/videos/European RoboCupJunior 2023, Varaždin - Documentary.mp4' />

            <div class='article-page'>
                <div class='content content-container'>
                    <h1 class='title'>Croatia</h1>
                    <p>
                        Unique in so many ways, Croatia’s roots stem from ancient times and its great cultural wealth
                        tells of a turbulent history going back to the Roman era and continuing through the Renaissance,
                        the baroque period and the Ottoman conquests right up to the present day, all of which is
                        exceptionally appealing to all who visit the country.
                    </p>
                    <p>
                        If we then add the rich cultural heritage, the amazing natural beauty which attracts visitors
                        throughout the year, the 8 national parks, 12 nature reserves and the numerous UNESCO world
                        heritage sites, Croatia truly becomes a country of incredible magnetic attraction.
                    </p>
                    <p>
                        Croatia’s attractions include beautiful beaches, one of the cleanest seas in the world, secluded
                        bays, lakes and mystical mountain peaks, clean rivers and potable water, fantastic cuisine,
                        prized wines and spirits, and a world-renowned cultural and natural heritage.
                    </p>
                    <p>
                        It is the home of the necktie, the parachute, the torpedo, the mechanical pencil, the filament
                        light bulb, the world’s fastest electric car and the second oldest film festival in the world,
                        the Dalmatian dog, Marco Polo, one of the oldest cities and parliaments in Europe, the second
                        longest stretch of defensive walls in Europe, the smallest cathedral, the oldest arboretum, and
                        the birthplace of great athletes.
                    </p>
                    <p>
                        It is a country of exceptional strategic significance, a criss-cross of roads that connect the
                        East and West of Europe, a beating artery, a country with a beautiful sea, green mountains,
                        beautiful plains, rich fields and oil deposits. And last but by no means least– the Croats
                        themselves! Sometimes the hospitality of the locals will make you wonder whether you are in your
                        own home or simply in a competition in Croatia!
                    </p>
                    <p>
                        Source:
                        <a href='https://www.htz.hr/en-GB/promo-materials/brochures'>
                            https://www.htz.hr/en-GB/promo-materials/brochures
                        </a>
                    </p>
                    <br />
                    <h1 class='title'>Varaždin</h1>
                    <p>
                        Location of{' '}
                        <a target='_blank' href='https://maps.app.goo.gl/CR9kWMuzvxfEr5v29?g_st=ic'>
                            Arena Varaždin
                        </a>
                    </p>
                    <p>
                        As to when the city was founded, we don’t exactly know. We know it was a long time ago, sometime
                        during the Middle Ages. Varaždin has always been a city loved by its inhabitants and admired by
                        its visitors for its beauty and unique spirit.
                    </p>
                    <p>
                        The city has played many great roles during its extensive history. It is a small and influential
                        cultural, economic, educational and tourist centre of northwestern Croatia and one of the
                        important urban points of the entire continental part of the country.
                    </p>
                    <p>
                        Varaždin is positioned on the crossroads of the historical regions of Styria, Međimurje, Zagorje
                        and the upper Drava Valley, without belonging to any of these. Varaždin is a special
                        micro-region with its own Varaždin identity, traditions and cultural patterns.
                    </p>
                    <p>
                        The city has always been open to the world. Since it originated at a crossroads of ancient Roman
                        roads, and developed into a trade and craft centre, people from all over Europe came to Varaždin
                        to work or make it their home. The greatest tourist value of Varaždin is its exceptional
                        monumental and artistic heritage gathered in one of the best preserved and richest Baroque urban
                        units, not only in Croatia but also in the wider region.
                    </p>
                    <p>
                        Baroque Varaždin was built according to the medieval plan, which means in a relatively small
                        area you can find preserved traces of all historical periods. Varaždin’s historic core is
                        adorned by preserved palaces, significant public buildings, valuable houses and villas in the
                        style of Baroque, Rococo, Classicism and Art Nouveau, as well as one of the oldest European city
                        halls.
                    </p>
                    <p>
                        Special attention is drawn to the large complex of the Old Town, the famous fortifications built
                        from the 14th to the 19th century. Varaždin churches, their bell towers and towers, but also the
                        immense artistic value of the interior of churches and monasteries are an indispensable part of
                        the story of historic Varaždin. Like everything in this beautiful, charming and preserved old
                        town – it is worth exploring. The plan of Varaždin with its irregular, circular streets and
                        alleys is primarily medieval.
                    </p>
                    <p>
                        Fortunately, the tradition of the role that city squares play in the social life, dynamics and
                        culture of the city, has pleasantly survived in Varaždin to the present day. The squares in the
                        historic core of Varaždin are superb for socializing, observing, commenting, having long
                        conversations and even longer coffee breaks – offering views as diverse as Varaždin itself.
                    </p>
                    <p>
                        Source: <a href='https://visitvarazdin.hr/en/'>https://visitvarazdin.hr/en/</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Location;
