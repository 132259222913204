import React from 'react';
import Rotator from '../components/rotator.jsx';
import '../styles/landing.css';
import '../styles/article.css';
import SEO from '../components/SEO.jsx';

const Competition = () => {
    return (
        <div>
            <SEO
                title='Competition'
                description='RoboCupJunior is a division of the RoboCup Federation that aims at promoting robotics and artificial intelligence in primary and secondary schools...'
            />
            <Rotator video='https://www.youtube.com/watch?v=ugV6FJ6YFnM' />

            <div class='article-page'>
                <div class='content content-container'>
                    <h1 class='title'>European RoboCupJunior Championship 2023</h1>
                    <p>
                        RoboCupJunior is a division of the RoboCup Federation that aims at promoting robotics and
                        artificial intelligence in primary and secondary schools, by organizing robotic competitions
                        focussed on young students under the age of 19.{' '}
                    </p>
                    <p>RoboCupJunior competitions are divided in three leagues: Soccer, Rescue and OnStage.</p>
                    <p>
                        The European RoboCupJunior Championship (EURCJ) is a new super-regional RoboCup event that has
                        the objective of organizing RoboCupJunior competition at European level. It is organized yearly
                        in different European countries.
                    </p>
                    <p>
                        EURCJ is organized by a committee formed by the European RoboCupJunior Regional Representatives
                        and by a Local Organizing Committee.
                    </p>
                    {/* <img src="./images/Varazdin_17.jpg" alt="article image"> */}
                    <p>
                        EURCJ Croatia 2023 is the fourth edition of EURCJ and is organized by the Croatian RoboCupJunior
                        Committee and Croatian Robotic Society in collaboration with other European RoboCupJunior
                        regional committees.
                    </p>
                    {/* <!-- <div class="highlighted">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard the printing and typesetting industry. Lorem Ipsum has been the</p>
            </div> --> */}
                    <p>
                        History of the competitions:
                        <br />
                        <a href='http://rcjcroatia2023.eu/' target='_blank'>
                            Croatia - 2023
                        </a>
                        <br />
                        <a href='http://2022.robocupjunior.eu/' target='_blank'>
                            Portugal - 2022
                        </a>
                        <br />
                        <a href='https://rcj2019.eu/' target='_blank'>
                            Germany - 2019
                        </a>
                        <br />
                        <a href='http://2018.robocupjunior.eu/' target='_blank'>
                            Italy - 2018
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Competition;
