import React, { useState, useEffect } from 'react';
import '../styles/navigation.css';
import { Button, Dropdown, Modal, Space } from 'antd';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

const Navigation = () => {
    const [navOpen, setNavOpen] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [cookieStatement, setCookieStatement] = useState(false);

    const aboutUsLinks = [
        {
            key: '1',
            label: (
                <a href='/competition' class='option'>
                    <span class='option-text'>Competition</span>
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a href='/organization' class='option'>
                    <span class='option-text'>Organization</span>
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a href='/location' class='option'>
                    <span class='option-text'>Location</span>
                </a>
            ),
        },
    ];

    const categoriesLinks = [
        {
            key: '1',
            label: (
                <a href='/onstage' class='option'>
                    <span class='option-text'>OnStage</span>
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a href='/soccer' class='option'>
                    <span class='option-text'>Soccer</span>
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a href='/rescue' class='option'>
                    <span class='option-text'>Rescue</span>
                </a>
            ),
        },
    ];

    const participantsLinks = [
        {
            key: '1',
            label: (
                <a href='/registration' class='option'>
                    <span class='option-text'>Registration</span>
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a href='/programme' class='option'>
                    <span class='option-text'>Programme</span>
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a href='/accomodation' class='option'>
                    <span class='option-text'>Accommodation</span>
                </a>
            ),
        },
        {
            key: '4',
            label: (
                <a href='/travel-information' class='option'>
                    <span class='option-text'>Travel information</span>
                </a>
            ),
        },
        {
            key: '5',
            label: (
                <a href='/math-works-seminar' class='option'>
                    <span class='option-text'>MathWorks seminar</span>
                </a>
            ),
        },
        {
            key: '6',
            label: (
                <a href='/social-activities' class='option'>
                    <span class='option-text'>Social activities</span>
                </a>
            ),
        },
        {
            key: '7',
            label: (
                <a href='http://robocup.zviz.net/european-robocup-junior-2023/' class='option' target='_blank'>
                    <span class='option-text'>Results</span>
                </a>
            ),
        }
    ];

    useEffect(() => {
        setRefresh(!refresh);
        console.log(window.location);
    }, [window.location]);

    const setCookies = () => {
        Cookies.set('cookie_statement', 'accepted', { expires: 1000 });
        setCookieStatement(false);
    };

    useEffect(() => {
        let cookiesValidation = Cookies.get('cookie_statement');
        if (cookiesValidation === undefined) {
            setCookieStatement(true);
        }
        console.log(cookiesValidation, 'cookie validation');
    }, []);

    return (
        <>
            <div className={window.location.pathname !== '/' ? 'article-page' : ''}>
                <div class={`nav ${navOpen}`}>
                    <a href='/' class='logo'>
                        <img
                            src={
                                window.location.pathname !== '/'
                                    ? '/images/horizontal-logo.png'
                                    : '/images/main-logo.png'
                            }
                            alt='logo'
                        />
                    </a>
                    <div class='right'>
                        <div class='links'>
                            <label class='burger burger2' for='burger2'>
                                <input
                                    class='hidden'
                                    id='burger2'
                                    type='checkbox'
                                    onClick={(e) => setNavOpen(navOpen === '' ? 'open' : '')}
                                />
                                <span></span>
                            </label>
                        </div>
                        <div class='desktop-placeholder'>
                            <a
                                href='https://junior.robocup.org/'
                                target='_blank'
                                class='robocupjunior-logo robocupjunior-logo-landing'>
                                <img
                                    src='https://junior.robocup.org/wp-content/uploads/2016/11/RCjr_high_L.jpg'
                                    alt='robocupjunior logo'
                                />
                            </a>
                        </div>
                        <div class='date'>
                            <p>
                                7<sup>th</sup> to 10<sup>th</sup> June 2023
                            </p>
                        </div>
                        <div class='dropdown'>
                            <p class='category'>ABOUT US</p>
                            <a href='/competition'>Competition</a>
                            <a href='/organization'>Organization</a>
                            <a href='/location'>Location</a>
                            <p class='category'>CATEGORIES</p>
                            <a href='/onstage'>OnStage</a>
                            <a href='/soccer'>Soccer</a>
                            <a href='/rescue'>Rescue</a>
                            <p class='category'>PARTICIPANTS</p>
                            <a href='/registration'>Registration</a>
                            <a href='/programme'>Programme</a>
                            <a href='/accomodation'>Accommodation</a>
                            <a href='/travel-information'>Travel information</a>
                            <a href='/math-works-seminar' class='option'>MathWorks seminar</a>
                            <a href='/social-activities' class='option'>Social activities</a>
                            <a href='http://robocup.zviz.net/european-robocup-junior-2023/' target='_blank'>Results</a>
                            <a href='/contact' class='category-link'>
                                Contact
                            </a>
                        </div>
                    </div>
                </div>
                <div class='nav-desktop'>
                    <div class='container content-container'>
                        <Dropdown
                            menu={{ items: aboutUsLinks }}
                            placement='bottom'
                            arrow={{ pointAtCenter: true }}
                            className='select-menu link'>
                            <Button>
                                <span class='sBtn-text'>ABOUT US</span>
                            </Button>
                        </Dropdown>
                        <Dropdown
                            menu={{ items: categoriesLinks }}
                            placement='bottom'
                            arrow={{ pointAtCenter: true }}
                            className='select-menu link'>
                            <Button>
                                <span class='sBtn-text'>CATEGORIES</span>
                            </Button>
                        </Dropdown>
                        <Dropdown
                            menu={{ items: participantsLinks }}
                            placement='bottom'
                            arrow={{ pointAtCenter: true }}
                            className='select-menu link'>
                            <Button>
                                <span class='sBtn-text'>PARTICIPANTS</span>
                            </Button>
                        </Dropdown>
                        <a href='/contact' class='select-menu link direct-link'>
                            <span class='sBtn-text'>CONTACT</span>
                        </a>
                    </div>
                </div>
            </div>
            <Modal title='Cookie consent' open={cookieStatement} onOk={setCookies} okText='Accept'>
                <div className='modal-content'>
                    <p>
                        This site utilizes cookies to improve the user experience. To understand how we handle your
                        personal information, please read our privacy statement which can be found{' '}
                        <a href='/files/privacy-policy.pdf' target='_blank' rel='noopener'>
                            here
                        </a>
                        .
                    </p>
                    <p>
                        By continuing to use our site, you are agreeing to our privacy policy and our use of cookies.
                        Please indicate your understanding and acceptance of our privacy policy by clicking accept.
                    </p>
                </div>
            </Modal>
        </>
    );
};

export default Navigation;
