import React from 'react';
import '../../styles/landing.css';

const FooterLinkedArticles = () => {
    return (
        <div class='linked-articles content-container'>
            <a href='/location' class='link'>
                <h2>About City of Varaždin</h2>
                <p>As to when the city was founded, we don’t exactly know. We know it was a long time...</p>
                <div class='arrow'>
                    <img src='/images/Strelica-bijela.svg' alt='arrow' />
                </div>
            </a>
            <div class='divider'>
                <div></div>
            </div>
            <div class='link'>
                <h2>Subleagues</h2>
                <p>
                    <a href='/rescue'>Rescue</a> <br />
                    <a href='/soccer'>Soccer</a>
                    <br />
                    <a href='/onstage'>OnStage</a>
                    <br />
                </p>
                <div class='arrow'>
                    <img src='/images/Strelica-bijela.svg' alt='arrow' />
                </div>
            </div>
        </div>
    );
};

export default FooterLinkedArticles;