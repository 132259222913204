import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.css';
import IndexPage from './pages/indexPage.jsx';
import { BrowserRouter as ReactRouter, Routes, Route } from 'react-router-dom';
import Footer from './components/footer.jsx';
import Navigation from './components/navigation.jsx';
import Competition from './pages/competition.jsx';
import Location from './pages/location';
import OnStage from './pages/onStage.jsx';
import Organization from './pages/organization.jsx';
import Rescue from './pages/rescue.jsx';
import Soccer from './pages/soccer';
import Contact from './pages/contact';
import Accomodation from './pages/accomodation';
import Programme from './pages/programme';
import Registration from './pages/registration';
import TravelInformation from './pages/travelInformation';
import MathWorks from './pages/MathWorks';
import SocialActivities from './pages/SocialActivities';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ReactRouter>
            <Navigation />
            <Routes>
                <Route index element={<IndexPage />} />
                <Route path='competition' element={<Competition />} />
                <Route path='location' element={<Location />} />
                <Route path='onstage' element={<OnStage />} />
                <Route path='organization' element={<Organization />} />
                <Route path='rescue' element={<Rescue />} />
                <Route path='soccer' element={<Soccer />} />
                <Route path='contact' element={<Contact />} />

                <Route path='accomodation' element={<Accomodation />} />
                <Route path='programme' element={<Programme />} />
                <Route path='registration' element={<Registration />} />
                <Route path='travel-information' element={<TravelInformation />} />
                <Route path='math-works-seminar' element={<MathWorks />} />
                <Route path='social-activities' element={<SocialActivities />} />

                <Route path='*' element={<h1>Error</h1>} />
            </Routes>
            <Footer />
        </ReactRouter>
    </React.StrictMode>
);
